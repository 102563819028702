import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PaymentSliceState {
    loading: boolean;
    error: Error | null;
    list: any[];
    count: number;
}

const initialState: PaymentSliceState = {
    loading: false,
    error: null,
    list: [],
    count: 0
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        startLoading: (state: PaymentSliceState) => {
            state.loading = true;
        },
        stopLoading: (state: PaymentSliceState) => {
            state.loading = false;
        },
        fetchedPayment: (state: PaymentSliceState, action: PayloadAction<{ list: any[], count: number}>) => {
            state.list = action.payload.list;
            state.count = action.payload.count;
        },
        changeStatusPayment: (state: PaymentSliceState, action: PayloadAction<{ id: number, isActive: boolean}>) => {
            state.list = state.list.map((list) => {
                if(list.id === action.payload.id) {
                    return {
                        ...list,
                        isActive: action.payload.isActive
                    }
                } else {
                    return list;
                }
            })
        } 
    }
})

export const paymentSliceActions = paymentSlice.actions;
export default paymentSlice.reducer;