import React, { FC, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './sidebar.scss';
// import logo from '../../assets/images/favicon.png';
import collapse from "../../assets/images/collapse.png";
import expand from "../../assets/images/expand.png";
import { Tooltip } from '@mui/material';
import useIsMobileViewHooks from '../../hooks/useIsMobileView';
import LogoutIcon from '../../assets/images/icons/logout';
import { removeStorageToken } from '../../utils/helpers/intex';

interface SubMenuConfig {
  name: string;
  path: string;
  icon?: React.ReactNode;
}

interface SidebarConfig {
  name: string;
  path: string;
  icon?: React.ReactNode;
  subMenu?: SubMenuConfig[];
}

interface SidebarProps {
  links: SidebarConfig[];
  onTabClick: (name: string) => void;
  setActiveComponent: (component: string) => void;
  isOpen: boolean;
  style?: React.CSSProperties;
}

const Sidebar: FC<SidebarProps> = ({ links, onTabClick, setActiveComponent, isOpen }) => {
  const navigate = useNavigate();
  const {
    isMobileView
  } = useIsMobileViewHooks();


  const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);
  const sidebarStyle: React.CSSProperties = {
    position: isMobileView ? "absolute" : "relative",
    zIndex: isMobileView ? 1000 : "auto",
    transition: "all 0.3s ease",
    overflowX: isMobileView ? "hidden" : undefined,
    overflowY: 'auto',
  };

  const handleTabClick = (link: SidebarConfig, index: number) => {
    if (link.subMenu) {
      setOpenSubmenu(openSubmenu === index ? null : index);
    } else {
      navigate(link.path);
      onTabClick(link.name);
      setActiveComponent(link.name);
    }
  };

  const logoutHandler = () => {
    removeStorageToken();
    window.location.href = "/login";
  };

  const location = useLocation();

  return (
    <div style={sidebarStyle}>
      {isOpen &&
        <div className={`sidebar open`}>
          {/* <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}> */}
          <div className="sidebar-header">
            {/* <img src={logo} className="logo" alt="Logo" /> */}
            <h1>Green Brothers</h1>
            <h4>International PVT. LTD.</h4>
          </div>
          <ul className='sidebar-menu-custom'>
            {links.map((link: SidebarConfig, index: number) => (
              <li key={index} className={`menu-item ${openSubmenu === index ? 'submenu-open' : '' } ${location.pathname === link.path ? 'active' : ''}`}>
                <Tooltip title={!isOpen ? link.name : ''} placement="right">
                  <div className="menu-link" onClick={() => handleTabClick(link, index)}>
                    <div className='menu-content' >
                      {link.icon && <span className="icon">{link.icon}</span>}
                    </div>
                    <div>
                      {isOpen && <span className="link-name">{link.name}</span>}
                    </div>
                    <div>
                      {link.subMenu && isOpen && (
                        <span className={`submenu-toggle ${openSubmenu === index ? 'open' : ''}`}>
                          {openSubmenu === index ? <img src={collapse} alt="collapse" /> : <img src={expand} alt="expand" />}
                        </span>
                      )}
                    </div>
                    {/* <a>
                    {link.icon && <span className="icon">{link.icon}</span>}
                    {isOpen && <span className="link-name">{link.name}</span>}
                  </a> */}
                  </div>
                </Tooltip>
                {link.subMenu && openSubmenu === index && (
                  <ul className={`submenu ${openSubmenu === index ? 'expanded' : 'collapsed'}`}>
                    {link.subMenu.map((sublink: SubMenuConfig, subIndex: number) => (
                      // <li key={subIndex} className="submenu-item" >
                      <li key={subIndex} className={`submenu-item ${location.pathname === sublink.path ? 'active' : ''}`}>
                        <Link to={sublink.path} onClick={() => {
                          onTabClick(sublink.name);
                          setActiveComponent(sublink.name);
                        }}>
                         <span className="icon">{sublink.icon}</span>
                          {sublink.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <li className={`menu-item`}>
              <div className="menu-link" onClick={logoutHandler}>
                <div className='menu-content'>
                  <span className="icon"><LogoutIcon/></span>
                </div>
                <div>
                  {isOpen && <span className="link-name">Logout</span>}
                </div>
                <div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      }
    </div>
  );
};

export default Sidebar;