import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "./assets/scss/App.scss";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import Fallback from "./components/fallback";
import Routes from "./routes";

import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Loader from "./components/loader/loader";
import { fetchLoginUser } from "./services/user.service";
import { appSliceActions } from "./redux/app";
import { getAuthToken } from "./utils/helpers/intex";


function App() {

  const dispatch = useDispatch();
  const { startLoading, stopLoading} =  appSliceActions

  useEffect(() => {
    const authToken = getAuthToken();
    const getUserData = async () => {
      dispatch(startLoading());
      try {
        const userData = await fetchLoginUser();
        if (userData && userData.success) {
          const { id, fullName, email, phoneNumber, roleId, companyId, accessModules, performInvoiceNo, invoiceNo, poNo } = userData.data;
          const loginUserDetails = {
            id,
            fullName,
            email,
            phoneNumber,
            roleId,
            companyId: companyId,
            accessModules,
            performInvoiceNo,
            invoiceNo,
            poNo
          };
          dispatch(appSliceActions.setLoginUser(loginUserDetails));
        }
      } catch (error) {
        console.error("Error :", error);
      }
      finally{
        dispatch(stopLoading());
      }
    };
    if(authToken) {
      getUserData(); 
    }
    // eslint-disable-next-line
  }, []);

  return (
    // <Provider store={store}>
      <ErrorBoundary FallbackComponent={Fallback}>
        <Loader />
        <Routes/>
        {/* <Router>
          <Layout>
        <Routes>
        <Routes>
          </Layout>
        </Router> */}
        <ToastContainer
          closeButton={false}
          hideProgressBar={true}
          autoClose={2000}
        />
      </ErrorBoundary>
    // </Provider>
  );
}

export default App;
